import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/gl-styles.css'
import '@/assets/css/toggleTab.css'
import '@/assets/css/fns-css.css'
import '@/assets/css/font-awesome.min.css'
import '@/assets/css/login.css'
import '@/assets/css/util.css'
import '@/assets/css/main.css'
import '@/assets/css/style2.css'
import VModal from 'vue-js-modal'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueSweetalert2 from 'vue-sweetalert2'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
import 'swiper/swiper-bundle.css'
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#FFD74C',
  cancelButtonColor: '#ff7674'
}
Vue.use(VueSweetalert2, options)
Vue.use(VModal)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
const configFirebase = {
  apiKey: "AIzaSyAcDHTiOfY3Si0-Xp6u_ZCGb8Kv4wNW8rk",
  authDomain: "get-88.firebaseapp.com",
  databaseURL: "https://get-88-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "get-88",
  storageBucket: "get-88.appspot.com",
  messagingSenderId: "39557754460",
  appId: "1:39557754460:web:f7e748c61e746d247e0099"
}
firebase.initializeApp(configFirebase)
Vue.config.productionTip = false
Vue.prototype.$music = null
let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
